import React from "react";
import IconContentComponent from "./iconContent";

const IconContentList = ({iconContents}) => {
    return (
        <div className="p-3 info-horizontal d-flex flex-column">
            {iconContents.map((item, index) => {
                return (
                    <div key={index}>
                        <IconContentComponent icon={item.image} title={item.title} description={item.description}/>
                    </div>
                );
            })}
        </div>
    )
}

export default IconContentList;
