import React from "react";
import SvgComponent from "../../tools/svg";
import {graphql} from "gatsby";

const IconContentComponent = ({icon, title, description}) => {
    return (
        <div className="p-3 info-horizontal row">
            <div className="col-lg-1">
                <div className="text-center">
                    <SvgComponent url={icon}/>
                </div>
            </div>
            <div className="col-lg-11 text-center">
                <h4>{title}</h4>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default IconContentComponent;

export const query = graphql`
  fragment IconContentFields on MarkdownRemarkFrontmatter {
    iconContents {
      image
      title
      description
    }
  }
`;
