import {graphql, Link} from "gatsby";
import React from "react";
import IconContentList from "./missions/iconContentList";
import showdown from 'showdown';

const converter = new showdown.Converter();

const MissionsComponent = ({title, subtitle, missions}) => {
    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 ms-auto me-auto text-center">
                        <h2 className={'display-1'}>{title}</h2>
                        <p dangerouslySetInnerHTML={{__html: converter.makeHtml(subtitle)}}/>
                    </div>
                </div>
                {missions.map((mission, key) => {
                        return (
                            <div key={key}>
                                <div className="row mt-5 justify-content-center">
                                    <div className={"col-md-5 order-" + (key % 2)}>
                                        <div className="card bg-cover text-center"
                                             style={{backgroundImage: `url(${mission.image})`}}>
                                            <div className="card-body z-index-2 py-3">
                                                <h2 className="text-white">{mission.mainTitle}</h2>
                                                <p className="text-white" dangerouslySetInnerHTML={{__html: converter.makeHtml(mission.mainDescription)}}/>
                                                <label className="badge badge-light text-dark">{mission.tag}</label>
                                            </div>
                                            <div className="mask bg-gradient-primary border-radius-lg"></div>
                                        </div>
                                    </div>
                                    <div className={"col-md-5 my-auto mt-md-auto mt-5 order-" + ((key + 1) % 2)}>
                                        <IconContentList iconContents={mission.iconContents}/>
                                    </div>
                                </div>
                                <hr className="horizontal dark my-6 mx-7"/>
                            </div>
                        );
                    }
                )}
            </div>
        </section>
    )
}

export default MissionsComponent;

export const query = graphql`
  fragment MissionsFields on MarkdownRemarkFrontmatter {
    ourMissions {
      title
      subtitle
      missions {
        mainTitle
        mainDescription
        tag
        image
        iconContents {
          image
          title
          description
        }
      }
    }
  }
`;
