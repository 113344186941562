import * as React from "react"

import LayoutComponent from "../components/layout";
import {graphql} from "gatsby";
import MetadataComponent from "../components/fields/metadata";
import MissionsHeaderComponent from "../components/fields/missions/header";
import FaqComponent from "../components/fields/faq";
import MissionsComponent from "../components/fields/missions";
import {useEffect, useState} from "react";
import { client } from '../../tina/__generated__/client';
import CallToActionComponent from "../components/fields/callToAction";
import RealisationsComponent from "../components/realisations";

const NosMissionsPage = ({data}) => {
    const [realisations, setRealisations] = useState([]);
    console.log(data);
    const {frontmatter} = data.markdownRemark;

    useEffect(() => {
        async function retrieveRealisations() {
            let reals = await client.queries.clientsConnection();

            if (typeof reals.data.clientsConnection.edges !== 'undefined') {
                setRealisations(reals.data.clientsConnection.edges)
            }
        }

        if(realisations.length == 0) {
            retrieveRealisations();
        }
    })

    return (
        <>
            <MetadataComponent
                description={frontmatter.metadata.description}
                title={frontmatter.metadata.title}
                keywords={frontmatter.metadata.keywords}
                code={frontmatter.metadata.code}
            />

            <LayoutComponent activeCategory={"nos-missions"}>
                <MissionsHeaderComponent
                    title={frontmatter.header.title}
                    subtitle={frontmatter.header.subtitle}
                    description={frontmatter.header.description}
                    image={frontmatter.header.image}
                    alert={frontmatter.header.alert}
                />

                <FaqComponent
                    title={frontmatter.faq.title}
                    subtitle={frontmatter.faq.subtitle}
                    questions={frontmatter.faq.questions}
                />

                <MissionsComponent
                    title={frontmatter.ourMissions.title}
                    subtitle={frontmatter.ourMissions.subtitle}
                    missions={frontmatter.ourMissions.missions}
                />
                {/*<RealisationsComponent
                    title={frontmatter.realisations.title}
                    subtitle={frontmatter.realisations.subtitle}
                    description={frontmatter.realisations.description}
                    realisations={realisations}
                />*/}

                <RealisationsComponent nbRealisations={6}/>

                <CallToActionComponent
                    title={frontmatter.callToAction.title}
                    link={frontmatter.callToAction.link}
                    reference={frontmatter.callToAction.reference}
                    image={frontmatter.callToAction.image}
                />
            </LayoutComponent>
        </>
    );
}

export default NosMissionsPage;

export const pageQuery = graphql`
query {
  markdownRemark(frontmatter: {metadata: {code: {eq: "missions"}}}) {
      frontmatter {
        ...MetadataFields
        ...HeaderFields
        ...FaqFields
        ...MissionsFields
        ...ActivityFields
        ...RealisationsFields
        ...CallToActionFields
      }
    
  }
}
`
