import {graphql, Link} from "gatsby";
import React from "react";
import showdown from 'showdown'

const converter = new showdown.Converter()

const FaqComponent = ({questions, title, subtitle}) => {
    return (
        <section className="py-4">
            <div className="container">
                <div className="row my-5">
                    <div className="col-md-6 mx-auto text-center">
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={{__html: converter.makeHtml(subtitle)}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="accordion" id="accordionExample">
                        {questions.map((question, index) => {
                            return (
                                <div key={index} className="accordion-item">
                                    <h5 className="accordion-header">
                                        <button className="accordion-button border-bottom collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`} dangerouslySetInnerHTML={{__html: converter.makeHtml(question.question)}}/>
                                    </h5>
                                    <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body" dangerouslySetInnerHTML={{__html: converter.makeHtml(question.answer)}}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqComponent;

export const query = graphql`
  fragment FaqFields on MarkdownRemarkFrontmatter {
    faq {
      title
      subtitle
      questions {
        question
        answer
      }
    }
  }
`;
